.row{
    margin-bottom: 0px;
}


h3,
p {
    margin: 0;
}
.directors__container-title{
    font-weight: bold;
    width: 100%;
    text-align: center;
    background-color: var(--color-main);
    color: var(--color-bg);
    padding: 1vh; 
}


.box:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-main);
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.box:hover:before {
    opacity: 80%;
}

.box {
    text-align: center;
    position: relative;
    overflow: hidden;
    color: #fff;
    width: 100%;
} 

.box:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 170%;
    top: 0;
    transform: skewX(135deg);
    transition: all 1s ease 0s;
    background-color: var(--color-secondary);
    z-index: 1;
}

.box:hover:after {
    left: -170%;
    top: 0;
}

.box img {
    width: 100%;
    height: 100%;
    max-height: 37vh;
    min-height: 37vh;
}

.box .box-content {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    transition: all 0.5s ease 0s;
}

.box:hover .box-content {
    bottom: 40%;
}

.box .box-content .name {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 10px;
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.box .box-content .post {
    display: block;
    font-size: 15px;
    font-style: italic;
    font-weight: 600;
}

@media screen and (max-width:990px) {
    .box {
        margin-bottom: 0px !important;
    }
}