.rimon__header {
    width: 100%;
    height: 52vh;
    position: relative;
    margin: 0;
    overflow: hidden;
}


#video {
    width: 100vw;
    min-height: 50%;
    min-width: 50%;
}

.rimon__header:after {
    content: 'חווה חקלאית חינוכית לנוער\A .בשביל האדם. דרך האדמה';
    white-space: pre;
    font-family: var(--font-family);
    font-size: 6.5vh;
    line-height: 13vh;
    font-weight: 700;
    letter-spacing: 0.20vw;
    text-align: center;
    color: var(--color-main);
    border-radius: 1vw;
    background: rgba(255, 255, 255, 0.5);
    width: 80vw;
    height: 25vh;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.rimon__header:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.backgroundImage_mobile {
    /* The image used */
    background-image: url("../../assets/mobileHeader.JPG");

    /* Add the blur effect */
    filter: brightness(80%) blur(0.5vw);
    /* Full height */
    height: 50vh;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    grid-column: 1/2;
    grid-row: 1/2;
}

.textdiv_mobile {
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/opacity/see-through */
    color: white;

    font-weight: bold;
    position: relative;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 20px;
    text-align: center;
    border-radius: 2vw;
    grid-column: 1/2;
    grid-row: 1/2;
    align-self: start;
    justify-self: start;
}

.rimon__header-mobile {
    display: grid;
    width: 100%;
    height: 50vh;
    margin: 0;
    overflow: hidden;
    background-size: cover;


}

.textdiv_mobile>p {
    font-size: 5vw;
    font-weight: bolder;
    color: var(--color-secondary);
    grid-column: 1/2;
    grid-row: 1/2;
}
.details_mobile{
    height: 32vh;

}
.farm_bluf {
    direction: rtl;
    text-align: center;
    line-height: 5vh;
    font-size: 2.5vh;
    font-weight: lighter;
    padding: 1vw 3vw 0vw 3vw;
}

.donate {
    width: 100vw;
    display: flex;
    justify-content: center; 
}

.donate a {
    text-align: center;
    padding: 0.5rem 2rem;
    color: var(--color-main);
    background: var(--color-secondary);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 4vw;
    line-height: 8vw;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
    width: 50vw;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 1050px) {
    #video {
        display: flex;
    }

    .rimon__header-image {
        display: none;
    }
}

/*TODO: need to edit ipad sizes.*/
/* @media screen and (max-width: 700px){
   
} */

/*Header for phones*/
@media screen and (max-width: 550px) {
    #video {
        position: absolute;
        width: 400vw;
        right: 400;
    }

    .rimon__header:before {
        display: none;
    }

    .rimon__header:after {
        position: absolute;
        content: 'חווה חקלאית חינוכית לנוער\A .בשביל האדם. דרך האדמה';
        white-space: pre;
        font-family: var(--font-family);
        width: 80vw;
        height: 22vw;
        position: absolute;
        font-size: 5.5vw;
        line-height: 11vw;
        font-weight: 700;
        letter-spacing: 0.20vw;
        text-align: center;
        color: var(--color-main);
        border-radius: 1vw;
        background: rgba(255, 255, 255, 0.5);
        bottom: 40%;
    }

    .rimon__header-image {
        display: block;
    }
}