.rimon__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 6rem;
    background: var(--color-secondary);
    height: 10vh;
}


.rimon__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.rimon__navbar_logo {
    height: 10vh;
    cursor: pointer;

}

.rimon__navbar-links_container {
    display: flex;
    flex-direction: row;
    margin-left: 3rem;
}

.link {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.link:focus {
    outline: none;
    background: none;
    border: none;
}

.link:hover {
    color: white;
}

.rimon__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.rimon__navbar-links_container p,
.rimon__navbar-sign p,
.rimon__navbar-menu_container p {
    color: var(--color-main);
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1.5rem;
    cursor: pointer;
}

.rimon__navbar-sign a {
    padding: 0.5rem 2rem;
    color: #fff;
    background: var(--color-main);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.rimon__navbar-menu {
    display: none;
}

.rimon__navbar-menu svg {
    cursor: pointer;
}



.rimon__navbar-menu_container p {
    margin: 1rem 0;
}

.rimon__navbar-menu_container-links-sign {
    display: none;
}

.donate_mobile_link {
    background: var(--color-secondary);
    width: 60vw;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5vw;
    direction: rtl;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

}


@media screen and (max-width: 1050px) {
    .rimon__navbar-links_container {
        display: none;
        width: 0;
    }

    .rimon__navbar-menu {
        display: flex;
    }
}

/*TODO: need to edit ipad sizes.*/
/* @media screen and (max-width: 700px){
    .rimon__navbar{
       padding: 2rem 4rem;
    }
} */

@media screen and (max-width: 550px) {
    .rimon__navbar {
        display: flex;
        padding: 0 5vw;
        justify-content: space-between;
    }

    .selected {
        color: var(--color-secondary);
    }

    .rimon__navbar_logo-container {
        margin: 0 auto;
        display: flex;
        flex: 3;
        justify-content: center;
        align-items: center;

    }


    .rimon__navbar-menu_container {
        text-align: center;
        background: white;
        width: 100vw;
        transition: width 2s;
        z-index: 1;
    }

    .link {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    .link:hover {
        color: var(--color-secondary);
    }



    .rimon__navbar-menu_container-links {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transition: ease height 0.3s;
    }


    .rimon__navbar-menu_container-links>p {
        padding-top: 1vh;
        padding-bottom: 1vh;

    }



    .rimon__navbar-menu_container-links-sign {
        display: block;
    }
}