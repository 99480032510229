.rimon__whatrimon-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-secondary);
    height: 62vh;
   
}
.rimon__whatrimon_trapezoid{
    border-bottom: 17vh solid var(--color-bg);
    border-left: 12vh solid transparent;
    border-right: 12vh solid transparent;
    height: 0;
    margin-top: 2vh;
    line-height: 17vh;
}
#rimon__whatrimon_trapezoid1 {
    width: 34vw;
    margin-top: 4vh;
  }

  #rimon__whatrimon_trapezoid2 {
    width: 47vw;
  }

  #rimon__whatrimon_trapezoid3 {
    width: 60vw; 
  }
  .rimon__whatrimon_trapezoid-text{
    display : inline-block;
    vertical-align : middle;
    font-size: 2.5vh;
    line-height : 3.2vh;
    text-align: center;
    direction: rtl;
    font-family: var(--font-family);
     color: var(--color-secondary);
  }
  .rimon__whatrimon_trapezoid-text span {
      font-weight: bold;
      color: var(--color-main);
  }

  @media screen and (max-width: 550px){
    .rimon__whatrimon-container{
      overflow-x: hidden;
    }
    .rimon__whatrimon_trapezoid{
      border-bottom: 100px solid var(--color-bg);
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      height: 0;
      margin-top: 1rem;
      line-height: 100px;
  }
    .rimon__whatrimon_trapezoid-text{
      font-size: 15px;
      line-height : 15px;
      padding: 0;
    }
    #rimon__whatrimon_trapezoid1 {
      width: 260px;
    }
  
    #rimon__whatrimon_trapezoid2 {
      width: 330px;
    }
  
    #rimon__whatrimon_trapezoid3 {
      width: 400px; 
    }
  }
