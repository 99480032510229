.mapDiv{
    height: 45vh;
     width: 33vw;
    
}
.marker{
  color: black;

  }
  

@media screen and (max-width: 700px) {
    .mapDiv{
        height: 30vh;
         width: 100vw;
    }

}
