.etc-container {
    width: 33vw;
    background: #eaeaea;
    height: 45vh;
    flex: 1;
    direction: rtl;
    padding: 4vw;
}

.etc-title {
    padding-bottom: 2vh;
}

li>span {
    padding-right: 2vw;
    color: var(--color-main);
}

li>span:hover {
    color: var(--color-secondary);
}

.contactList {
    margin-top: 1vh;
    font-size: 2.5vh;

}

.icon {
    color: var(--color-secondary);
    height: 5vh;
}

.icon:hover {
    color: var(--color-main);

}
.meetUs-title{
    font-size: 2vw;
}


@media screen and (max-width: 700px) {
    .etc-container {
        width: 100vw;
    }
    .meetUs-title{
        font-size: 4vh;
    }
    .contactList{
        margin-top: 1.5vh;
        font-size: 2.5vh;
    }
    .icon {
        height: 4vh;
    }
    
}