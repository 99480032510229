.timeline{
    background: var(--color-main);
    height: wrap;
}

.timelineTitle{
    color: white;
    width: 100%;
    margin-bottom: 1vh;
}

ul{
    direction: rtl;
    list-style-type: disc;
}
h3,li{
    direction: rtl;
}

.liText{
    list-style: inside;
    display: flex;
}

.liText > div{
    margin-left: 1vw;
}

.dates{
    color: white;
}

@media screen and (max-width: 550px) {
    .timeline{
        background: var(--color-main);
        height: 120vh;
        overflow-x: hidden;
    }
    .timelineTitle{
        display: none;
    }
    .vertical-timeline-element-title{
        font-size: 8vw;
    }
    .vertical-timeline-element-text{
        font-size: 4vw;
        padding-top: 1vh;
    }
}