.donors {
    height: 20vh;
    width: 30vw;
}

.donors_title {
    margin: 1vh 0 2vh 0;
    font-weight: bold;
    color: var(--color-main);
}

.ourDonors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
    flex-wrap: wrap;
    width: 100%;

}



.donor {
    /* flex-basis: 100%; */
    display: flex;
    flex-direction: column;
}

.donor_logo {
    width: 6vw;
    filter: grayscale(100%);
    transition: all 0.3s;
}


.donor_logo:hover {
    filter: grayscale(0%);
}

@media screen and (max-width: 550px) {

    .donor,
    .donor_logo {
        width: 12vw;
        filter: grayscale(100%);
        transition: all 0.3s;
    }

}